/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    span: "span",
    h2: "h2",
    a: "a",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "WebSockets are a way to communicate between a client and a server. The communication is bi-directional that is data can flow in both directions: from the client to the server and from the server to the client. Since WebSockets are always open, they allow for real-time data flow in an application, which makes way for real-time information flow back and forth."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 74%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAe3N4RuIy//EABkQAQACAwAAAAAAAAAAAAAAAAEAAhASMf/aAAgBAQABBQKDiyza8Of/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFn/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EABwQAAEDBQAAAAAAAAAAAAAAAAABEEEREjEyUf/aAAgBAQAGPwIzcy0SDWOt/8QAHRAAAgIDAAMAAAAAAAAAAAAAAREAITFBURBxgf/aAAgBAQABPyEkvFdce18qXgHJTC9yzHf25CJAkIz/2gAMAwEAAgADAAAAEHP/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxAJs//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEIz/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFRQWGh8P/aAAgBAQABPxCUDfIAmDTQdHA93nBUFI9dYvEdhF6YXGiP1fcI0oKDY5//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"websocket-main\"\n        title=\"\"\n        data-src=\"/static/b40a3836d1d6a62a1c5d8dc4ee4989a8/a24e6/websocket-main.jpg\"\n        data-srcset=\"/static/b40a3836d1d6a62a1c5d8dc4ee4989a8/a6b4f/websocket-main.jpg 200w,\n/static/b40a3836d1d6a62a1c5d8dc4ee4989a8/a6407/websocket-main.jpg 400w,\n/static/b40a3836d1d6a62a1c5d8dc4ee4989a8/a24e6/websocket-main.jpg 800w,\n/static/b40a3836d1d6a62a1c5d8dc4ee4989a8/a39c8/websocket-main.jpg 827w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "WebSockets are an advanced technology that makes it possible to open an interactive communication session between the user's browser and a server. WebSockets lets you send messages to a server and trigger event-driven responses. They are not required to poll the server all the time for replies."), "\n", React.createElement(_components.h2, {
    id: "event-driven-architecture-can-achieve-real-time-apis"
  }, "Event Driven architecture can achieve real time APIs"), "\n", React.createElement(_components.p, null, "“Real-time” or event driven API is ubiquitous in the modern application stack. Unlike request-response APIs in which a requestor sends a request to a server to receive a response, in event-driven APIs, an event or a set of events triggers a response from a server. The event could be technical or business in nature. An event is a change from one state to another. Such as the change from having zero unread messages to 2 unread messages."), "\n", React.createElement(_components.p, null, "Event-driven architectures are appealing to API developers because they function very well in asynchronous environments. By crafting APIs that trigger certain functions on new event delivery, API systems don’t have to inherently wait for synchronous delivery or real-time communication."), "\n", React.createElement(_components.p, null, "A major event driver protocol is WebSockets. To understand WebSockets, we need to clear few of the associated fundamentals."), "\n", React.createElement(_components.h2, {
    id: "what-are-sockets"
  }, "What are sockets?"), "\n", React.createElement(_components.p, null, "A socket is a “port” through which data goes in and out of. A socket is a “port” through which data goes in and out of. Protocols interpret the data going to and from the socket and the machines/ applications that are communicating with each other. A major protocol is HTTP."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/cloud-based-mobile-first-critical-to-yelowtaxi/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 15.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAWkgegk//8QAFxABAQEBAAAAAAAAAAAAAAAAAQIQEf/aAAgBAQABBQKFb5n/xAAWEQADAAAAAAAAAAAAAAAAAAACEDH/2gAIAQMBAT8BCL//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQMx/9oACAECAQE/AbtS/8QAFxAAAwEAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAQAGPwJht//EABkQAQEAAwEAAAAAAAAAAAAAAAEAETFRkf/aAAgBAQABPyFQJBgblzzy/9oADAMBAAIAAwAAABCH7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxCgMv/EABgRAAMBAQAAAAAAAAAAAAAAAAABIRFh/9oACAECAQE/EIngz0//xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMZH/2gAIAQEAAT8QwBEF5sfotNLYitPCf//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"websocket-main-cta-1\"\n        title=\"\"\n        data-src=\"/static/2917baddfbc226bd6792f4d9f5f7ff6c/a24e6/websocket-main-cta-1.jpg\"\n        data-srcset=\"/static/2917baddfbc226bd6792f4d9f5f7ff6c/a6b4f/websocket-main-cta-1.jpg 200w,\n/static/2917baddfbc226bd6792f4d9f5f7ff6c/a6407/websocket-main-cta-1.jpg 400w,\n/static/2917baddfbc226bd6792f4d9f5f7ff6c/a24e6/websocket-main-cta-1.jpg 800w,\n/static/2917baddfbc226bd6792f4d9f5f7ff6c/a39c8/websocket-main-cta-1.jpg 827w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "the-very-limitation-of-http"
  }, "The very limitation of HTTP"), "\n", React.createElement(_components.p, null, "Every time you request to download html, video or an image, a port is opened, data is transferred, and the port closes."), "\n", React.createElement(_components.p, null, "The consecutive closing and opening form overhead, and for a few applications, particularly those that need swift replies, real-time communications, or display chunks of data, this doesn’t work."), "\n", React.createElement(_components.p, null, "Another constraint with HTTP is the “pull” paradigm. The browser pulls the info from servers. The server doesn’t push data to the browser when it wants to. This means that browsers must poll the server for fresh info by echoing requests every so many seconds or minutes to learn if there is something new. The need to add Sockets to browsers was mounting."), "\n", React.createElement(_components.p, null, "The WebSockets were made a standard in 2011. This lets public to employ the WebSocket protocol, which is flexible for exchanging data between servers and the browser, as well as Peer-to-Peer (P2P), or direct communication between browsers. Unlike HTTP, the socket that is connected to the server stays “open” for communication. That means data can be “pushed” to the browser in near real-time whenever required."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read More:  ", React.createElement(_components.a, {
    href: "/blog/role-of-gps-tracking-taxi-business/"
  }, "Role of GPS tracking in Taxi Business")), "\n"), "\n", React.createElement(_components.h2, {
    id: "websockets-in-taxi-booking-systems"
  }, "WebSockets in taxi booking systems"), "\n", React.createElement(_components.p, null, "Modern logistics solutions are software-based and are generally hosted on the client’s server and almost all of them include real-time tracking of their fleet vehicles."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 54.49999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAHWLFAhbD//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQISIUH/2gAIAQEAAQUCa0sTOs//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8BSP/EABgQAAMBAQAAAAAAAAAAAAAAAAABEHER/9oACAEBAAY/AuGRiyf/xAAZEAADAAMAAAAAAAAAAAAAAAAAATERcZH/2gAIAQEAAT8h4Q3XhqCCNRBo/9oADAMBAAIAAwAAABCLP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQMBAT8QBmE9v//EABcRAAMBAAAAAAAAAAAAAAAAAAARIQH/2gAIAQIBAT8Qrpij/8QAHBABAAIDAAMAAAAAAAAAAAAAAQARITFRQWGh/9oACAEBAAE/EL0tc73EsEBI+fcGDKtZmCGxUxWLm0vyEGGDhP/Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"websocket-layer\"\n        title=\"\"\n        data-src=\"/static/5e6de27cb0942b56d8ee2a6eb0462a26/a24e6/websocket-layer.jpg\"\n        data-srcset=\"/static/5e6de27cb0942b56d8ee2a6eb0462a26/a6b4f/websocket-layer.jpg 200w,\n/static/5e6de27cb0942b56d8ee2a6eb0462a26/a6407/websocket-layer.jpg 400w,\n/static/5e6de27cb0942b56d8ee2a6eb0462a26/a24e6/websocket-layer.jpg 800w,\n/static/5e6de27cb0942b56d8ee2a6eb0462a26/a39c8/websocket-layer.jpg 827w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "If we look at the above example, these different clients are different people around the world in their browser or app. They either enter a URL in their web browser or download the app to access their part of ", React.createElement(_components.a, {
    href: "/taxi-app-development-solution"
  }, "taxi booking system"), ". Of all the person trying to access the system, John requests a taxi with the app. He enters the destination, and clicks ‘Ride Now’. That request is going to instantly be updated in all the nearby drivers to John connected to the server. This is WebSockets in action."), "\n", React.createElement(_components.p, null, "When John requested the taxi from the app and a driver accepted his request, he is opening up WebSocket between him and the driver. We have all of these different clients, browsers, and ecosystems with their own WebSocket connection to the server. Data can flow back and forth between it in real time because these are always open. When John requests a ride, he is actually sending that message down this WebSocket to the server."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read More:  ", React.createElement(_components.a, {
    href: "/blog/role-of-gps-tracking-taxi-business/"
  }, "Vehicle Tracking System in Academic Institutions")), "\n"), "\n", React.createElement(_components.p, null, "The server receives it and sends it down all these other WebSockets, which are connecting these clients to each individual client. They can see that updating message in their app. The message is going to send the WebSocket to the server. Then the server sends it to each WebSocket and to all the remaining clients. That is why they see this message instantaneously."), "\n", React.createElement(_components.h2, {
    id: "a-real-time-data-transfer"
  }, "A real-time data transfer"), "\n", React.createElement(_components.p, null, "The data transfer occurs in real time. We're not using any kind of AJAX request from each of these different clients to request any new data from the server. It's all happening without the client having to make any additional requests at all."), "\n", React.createElement(_components.p, null, "There's tons of different uses for WebSockets that go beyond chat rooms and real-time tracking system. They include making multiply multiplayer browser games, collaborative code editing software, some kind of live text for sports on news websites, online drawing canvases, real time to-do apps, etc. There are tons of different uses for WebSockets."), "\n", React.createElement(_components.p, null, "We use WebSockets to send data to a server. the server then sends it down to this client and shows it in real time. There is no need to make any kind of requests from this client to the server at all. It's just open and listening through this WebSocket and receiving data."), "\n", React.createElement(_components.h2, {
    id: "websockets-in-real-time-vehicle-tracking"
  }, "WebSockets in real-time vehicle tracking"), "\n", React.createElement(_components.p, null, "A WebSocket opens up ", React.createElement(_components.a, {
    href: "/blog/commuter-between-driver-and-rider-taxi-app"
  }, "between the drivers and passenger"), " as soon as the driver accepts the passenger request to ride. WebSockets facilitates the entire riding experience till the driver completes a ride. The entire tracking system is event triggered. For example, when a driver accepts a ride, the taxi software counts it as the event and notifies the passengers by updating his current app screen with driver’s name and other details. The same goes when a driver arrives or is waiting for you. A different set of events triggers owing to change of the driver’s position with that of the passenger. WebSockets are the primary reason Uber is so precise with its notifications and mapping services."), "\n", React.createElement(_components.h2, {
    id: "why-not-push-notification"
  }, "Why not Push-notification?"), "\n", React.createElement(_components.p, null, "You would be like why there is a need of WebSockets when the same can be achieved with push notification services too. The problem is push notifications are unreliable. If the either of the communicating client is offline, push notification services won’t know the difference and will still attempt to deliver the notification and, ultimately, fail."), "\n", React.createElement(_components.p, null, "WebSockets, on the other hand, will suspend notifications to its client whenever they are offline. They will wait for the client to come online before reattempting. The server will know of the condition the whole time and won’t settle unless it learns the notifications has been successfully delivered to the client."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 12%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECA//EABUBAQEAAAAAAAAAAAAAAAAAAAIE/9oADAMBAAIQAxAAAAHSpZDIS//EABcQAAMBAAAAAAAAAAAAAAAAAAACMQH/2gAIAQEAAQUCe4Pf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExAv/aAAgBAwEBPwF01T//xAAWEQADAAAAAAAAAAAAAAAAAAAAAjH/2gAIAQIBAT8BWCw//8QAFhAAAwAAAAAAAAAAAAAAAAAAARBx/9oACAEBAAY/AhH/AP/EABgQAAIDAAAAAAAAAAAAAAAAAAAQMWGx/9oACAEBAAE/IY9UwP/aAAwDAQACAAMAAAAQf+//xAAVEQEBAAAAAAAAAAAAAAAAAAAAMf/aAAgBAwEBPxC6j//EABURAQEAAAAAAAAAAAAAAAAAAAAx/9oACAECAQE/EHB//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAEhMUERwf/aAAgBAQABPxCGKgRdUb6Vj//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"websocket-main-cta-2\"\n        title=\"\"\n        data-src=\"/static/65d7167fc889499e8d9ef59d62cf986d/a24e6/websocket-main-cta-2.jpg\"\n        data-srcset=\"/static/65d7167fc889499e8d9ef59d62cf986d/a6b4f/websocket-main-cta-2.jpg 200w,\n/static/65d7167fc889499e8d9ef59d62cf986d/a6407/websocket-main-cta-2.jpg 400w,\n/static/65d7167fc889499e8d9ef59d62cf986d/a24e6/websocket-main-cta-2.jpg 800w,\n/static/65d7167fc889499e8d9ef59d62cf986d/a39c8/websocket-main-cta-2.jpg 827w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
